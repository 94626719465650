import React from 'react'
import axios from 'axios'
export default class AboutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/about/getbio`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }

  render() {
    const { data } = this.state
    let about = ''
    let research = ''
    let photoUrl = ''

    if (data) {
      about = data.Bio
      research = data.Research
      photoUrl = `${process.env.API_URL}${data.Photo}`
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Sobre</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">              
              <div className="col-md-7 col-sm-12 col-xs-12">
                <div className="about_content">
                  <h1>Em Laços</h1>
                  <span dangerouslySetInnerHTML={{__html: about}} />
                </div>
              </div>
              <div className="col-md-5 col-sm-12 col-xs-12">
                <div className="about-slide">
                  <img src={photoUrl} />
                </div>
              </div>             
            </div>
          </div>
        </section>
      </div>
    )
  }
}
